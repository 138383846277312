.ajaxkosik-popup {width:570px; z-index:1000; border-radius:2px; text-align:left; font: sans-serif; font-size:13px; color:black; box-shadow:rgba(0,0,0,0.5) 0px 0px 10px; background:white; line-height: 17px;}

.ajaxkosik-closer {position:absolute; right: 0px; top: 0px; width:32px; height:32px; background:url(close.png) no-repeat right top / 100% auto; cursor:pointer; display:none;}
.ajaxkosik-closer:hover {background-position:right bottom;}

.ajaxkosik-nadpis {font-size:115%; text-align:left; font-weight:bold; padding:20px 20px 17px 20px; }
.ajaxkosik-zprava {margin:20px; margin-top:0px; padding:9px 18px; background:#ccc; border-radius:2px; box-shadow:rgba(0,0,0,0.3) 0 1px 2px; border:solid 1px #444; border-color:rgba(0,0,0,0.4);}
.ajaxkosik-text {padding:9px 20px 20px 20px;}

.ajaxkosik-say {
	padding-top: 0px;
	padding-bottom: 15px;
}
.ajaxkosik-say .good {
	margin: 0;
}

.ajaxkosik-produkty {padding:0px 20px 12px 20px;}
.ajaxkosik-radek {padding:3px 0px;}
.ajaxkosik-obrazek {display:block; float:left; margin-right:10px; width:75px; margin-left:10px;}
.ajaxkosik-obrazek img {max-width:75px; max-height:75px; box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px;}
.ajaxkosik-stred {float:left; padding-top:3px; width:280px;}
.ajaxkosik-nazev {padding-bottom:4px;}
.ajaxkosik-detail {color:#555; font-size:85%; }
.ajaxkosik-kod {color:#555; font-size:85%; }
.ajaxkosik-pocet {float:left; padding-top:3px; margin-left:10px; text-align:right; width:40px;}
.ajaxkosik-cena {float:right; padding-top:3px; text-align:right; font-weight:bold; width:70px; padding-right:10px;}

.ajaxkosik-poplatky {padding:10px; color:#555; margin:0px 20px; border-top:solid 1px #e5e5e5; border-top-color:rgba(0,0,0,0.2); }
.ajaxkosik-poplatky-nazev {float:left;}
.ajaxkosik-poplatky-cena {text-align:right;}

.ajaxkosik-remaining {padding:10px; text-align: center; color:#555; margin:0px 20px; border-top:solid 1px #e5e5e5; border-top-color:rgba(0,0,0,0.2); }
.ajaxkosik-remaining b {color: black;}

.ajaxkosik-zdarma {padding:10px; text-align: center; color:#555; margin:0px 20px; border-top:solid 1px #e5e5e5; border-top-color:rgba(0,0,0,0.2); }
.ajaxkosik-zdarma b {color: black;}

.ajaxkosik-total {padding:7px 10px 0px 10px; border-top:solid 1px #e5e5e5; border-top-color:rgba(0,0,0,0.2); margin:0px 20px; font-weight:bold; height:27px;}
.ajaxkosik-total-nazev {float:left; padding-top:2px;}
.ajaxkosik-total-cena {text-align:right; font-size:15px; padding-top:1px;}

.ajaxkosik-buttons {padding:20px; background:rgba(0,0,0,0.14); box-shadow:inset rgba(0,0,0,0.2) 0px 1px 4px; border-top:solid 1px #444; border-top-color:rgba(0,0,0,0.3);}

.ajaxkosik-buttons a {
	display:inline-block; color:black; text-decoration:none; font-size:13px; background-color:#999; color:white; background-color:rgba(0,0,0,0.3);
	border:none; border:solid 1px #444; border-color:rgba(0,0,0,0.5); padding:9px 16px 9px 16px; border-radius:3px; text-shadow:rgba(0,0,0,0.4) 0px -1px 0px;
	background-image:url("button-gradient.png"); background-repeat:repeat-x; background-position:left top; box-shadow:inset rgba(255,255,255,0.4) 0px 0px 3px;
	cursor:pointer;
	font-family:Arial, sans-serif;
	box-shadow:rgba(0,0,0,0.3) 0px 2px 3px;	
}

.ajaxkosik-buttons a:hover {color:white; text-decoration:none; background-position:left bottom; box-shadow:rgba(0,0,0,0.4) 0px 2px 4px;}
.ajaxkosik-buttons a:active {margin-top:1px; box-shadow:rgba(0,0,0,0.3) 0px 1px 2px;}

.ajaxkosik-button-kosik {float:right;}
.ajaxkosik-button-pokracovat {float:right; margin-left:10px;}

.ajaxkosik-overlay-fallback {background-image:url("bg-fallback.png");}
.ajaxkosik-custom-html {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 20px;
    padding: 10px 10px 14px;
    line-height: 16px;
}

.ajaxkosik-crossselling {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	padding: 10px 20px;
	background-color: rgba(0,0,0,0.07);
}

.ajaxkosik-crossselling-heading {
	font-size: 15px;
	font-weight: bold;
	padding-bottom: 10px;
}

.ajaxkosik-crossselling-note {
	padding-top: 10px;
	font-size: 12px;
	color: #666;
}

.ajaxkosik-crossselling-prod {
	display: block;
	float: left;
	width: 32%;
	margin-right: 2%;
	text-align: center;
	text-decoration: none;
	position: relative;
}

.ajaxkosik-crossselling-prod, .ajaxkosik-crossselling-a {
	-webkit-transition: all .25s;
	   -moz-transition: all .25s;
	    -ms-transition: all .25s;
	     -o-transition: all .25s;
	        transition: all .25s;	
}

.ajaxkosik-crossselling-a {
	display: block;
	text-decoration: none;
	padding: 5px 0px 3px 0px;	
}

.ajaxkosik-crossselling-a:hover {
	text-decoration: none;
}

.ajaxkosik-crossselling-prod:hover a {
	background-color: rgba(0,0,0,0.1);
	text-decoration: none;
}

.ajaxkosik-crossselling .cs-form-place {
	height: 34px;
}

.ajaxkosik-crossselling-prod:nth-child(3n+2) {
	margin-right: 0;
}

.ajaxkosik-crossselling .cs-nazev {
	padding: 0px 10px 6px;	
}

.ajaxkosik-crossselling .cs-obrazek {
	padding-bottom: 8px;
	padding-top: 3px;
}

.ajaxkosik-crossselling .cs-obrazek img {
	max-width: 100%;
	max-height: 70px;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px;
}

.ajaxkosik-crossselling .ajaxkosik-crossselling-prod a:hover .cs-obrazek img {
	box-shadow: rgba(0, 0, 0, 0.4) 0 0 4px;
}

.ajaxkosik-crossselling .cs-kod {
	padding: 0px 10px 4px;	
	color: #666;
}

.ajaxkosik-crossselling .cs-cena {
	padding: 0px 10px 4px;	
}

.ajaxkosik-crossselling .cs-buy {
	padding: 2px 0px 4px;		
	position: absolute;
	width:100%;
	text-align: center;
	bottom: 5px;
	left: 0;
}

.ajaxkosik-crossselling .cs-btn {
	text-decoration:none; font-size:12px;
	border:solid 1px #444; border-color:rgba(0,0,0,0.5); padding:4px 6px; border-radius:2px; text-shadow:rgba(0,0,0,0.4) 0px -1px 0px;
	background-image:url("button-gradient.png"); 
	background-repeat:repeat-x; 
	background-position:left top; 
	cursor:pointer;
	font-family:Arial, sans-serif;
	box-shadow:rgba(0,0,0,0.3) 0px 2px 3px;	
	height: 26px;
	box-sizing: border-box;
	vertical-align: middle;
}

.ajaxkosik-crossselling .cs-btn :active {margin-top:1px; box-shadow:rgba(0,0,0,0.3) 0px 1px 2px;}

.ajaxkosik-crossselling .cs-input {
	border:solid 1px #444; border-color:rgba(0,0,0,0.5);
	background: white;
	margin-right: 5px;
	font-family:Arial, sans-serif;
	font-size:12px;
	height: 26px;
	box-sizing: border-box;
	vertical-align: middle;
	width: 60px;
}


@media (max-width: 600px) {
	.ajaxkosik-popup { width: 90%; max-height:90%;}
	.ajaxkosik-nadpis {padding: 14px 20px 8px 20px;}
	.ajaxkosik-buttons {}
	.ajaxkosik-buttons a {display: block; margin: 0 0 5px 0; width:auto; float: none; clear: both;}
	.ajaxkosik-zprava {margin: 8px 20px; padding: 6px 12px;}
	.ajaxkosik-produkty {display: none; }
	/* .ajaxkosik-stred {float:none; display: block; width:auto;} */
	.ajaxkosik-obrazek {display: none; }
	.ajaxkosik-radek {clear: both;}
	.ajaxkosik-total {border-top: none;}
	.ajaxkosik-pocet {float: right; width: 35px; margin-left: 0px;}
	.ajaxkosik-crossselling {display: none; }	
}

@media (max-height: 650px) {
	.ajaxkosik-produkty {
		display: block;
		max-height: 140px;
		overflow: auto;
	}
	.ajaxkosik-total {border-top:solid 1px #e5e5e5;}

	.ajaxkosik-popup {
		max-height: none;
		position: absolute !important;
		margin-top: 0 !important;
	}

	.ajaxkosik-crossselling {display: none; }	
}

@media (min-height: 549px) and (max-width: 600px) {
	.ajaxkosik-popup.init-with-small-screen {
		top: 50% !important;
	}
	.ajaxkosik-produkty {
		max-height: 140px;
		overflow: auto;
		display: block;		
	}	
}
